import { ReactComponent as Logo } from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Nav = ({ authState, authDispatch }) => {
  const navigate = useNavigate();

  const logout = () => {
    authDispatch({ type: "logged-out" });
    localStorage.removeItem("auth-token");
    toast.success("Signed out successfully");
    setTimeout(() => {
      navigate("/login");
    }, 700);
  };

  return (
    <div className="w-full bg-black flex flex-row justify-between items-center text-white h-12 px-5">
      <div
        className="flex flex-row justify-start items-center gap-3 cursor-pointer"
        onClick={() => navigate("/")}
      >
        <Logo className="w-auto h-6" />
        <p className="cursor-pointer font-serif font-medium tracking-wide">
          Augusta Museum of History
        </p>
      </div>
      <div className="flex flex-row justify-end items-center gap-5 font-body uppercase text-xs font-semibold">
        {authState.isAuth ? (
          <>
            <p className="cursor-pointer" onClick={() => navigate("/")}>
              Home
            </p>
            <p
              className="cursor-pointer"
              onClick={() => navigate("/scanTickets")}
            >
              Ticket-Scan
            </p>
            <p className="cursor-pointer" onClick={() => navigate("/events")}>
              Events
            </p>
            <p className="cursor-pointer" onClick={() => logout()}>
              Sign Out
            </p>
          </>
        ) : (
          <p className="cursor-pointer" onClick={() => navigate("/login")}>
            Login
          </p>
        )}
      </div>
    </div>
  );
};

export default Nav;

import { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ClaimTicket,
  GetEvent,
  UpdateEvent,
  DeleteEvent,
} from "../actions/events";
import dayjs from "dayjs";
import _ from "lodash";
import { toast } from "react-hot-toast";
import { DatePicker, InputNumber, Select } from "antd";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const Tickets = () => {
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [editDate, setEditDate] = useState(false);
  const [eventDate, setEventDate] = useState(null);
  const [eventTime, setEventTime] = useState(null);
  const [editSeats, setEditSeats] = useState(false);
  const [availableSeats, setAvailableSeats] = useState(null);

  let { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetEvent(id)
        .then((res) => {
          setEvent(res.data.event);
          setTickets(res.data.tickets);
          setTimeout(() => {
            setLoading(false);
          }, 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const validateTicket = (ticketId) => {
    setLoading(true);
    ClaimTicket({ purchaseId: ticketId, eventId: id })
      .then((res) => {
        GetEvent(id)
          .then((res) => {
            setEvent(res.data.event);
            setTickets(res.data.tickets);
            setTimeout(() => {
              setLoading(false);
            }, 700);
            toast.success("Ticket validated successfully!");
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            setLoading(false);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const datePick = (date, dateString) => {
    setEventDate(dayjs(date).format("YYYY-MM-DD"));
  };

  const timePick = (val) => {
    setEventTime(val);
  };

  const cancelDateEdit = () => {
    setEventDate(null);
    setEventTime(null);
    setEditDate(false);
  };

  const submitDateEdit = () => {
    if (eventDate && eventTime) {
      let date = eventDate + " " + eventTime;
      let formatted = dayjs(date, "YYYY-MM-DD hh:mm A");
      setLoading(true);
      UpdateEvent(id, { isDate: true, eventDate: formatted.toJSON() })
        .then((res) => {
          cancelDateEdit();
          GetEvent(id)
            .then((res) => {
              setEvent(res.data.event);
              setTickets(res.data.tickets);
              setTimeout(() => {
                setLoading(false);
                toast.success(
                  "Date and time of the event updated successfully!"
                );
              }, 700);
            })
            .catch((err) => {
              toast.error(err.response.data.message);
              setLoading(false);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    } else {
      if (!eventDate) {
        toast.error("Please select a new date of the event.");
      } else if (!eventTime) {
        toast.error("Please select a new time of the event.");
      } else {
        toast.error("Please select a new date and time of the event.");
      }
    }
  };

  const cancelSeatEdit = () => {
    setAvailableSeats(null);
    setEditSeats(false);
  };

  const submitSeatEdit = () => {
    if (availableSeats) {
      if (availableSeats < event.ticketsSold) {
        toast.error(
          "The number of available seats cannot be less than the number of tickets sold."
        );
      } else {
        UpdateEvent(id, { isTickets: true, tickets: availableSeats })
          .then((res) => {
            cancelSeatEdit();
            GetEvent(id)
              .then((res) => {
                setEvent(res.data.event);
                setTickets(res.data.tickets);
                setTimeout(() => {
                  setLoading(false);
                  toast.success(
                    "Number of available seats updated successfully!"
                  );
                }, 700);
              })
              .catch((err) => {
                toast.error(err.response.data.message);
                setLoading(false);
              });
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message);
          });
      }
    } else {
      toast.error("Please enter a valid number of available seats.");
    }
  };

  const submitEventDelete = () => {
    setLoading(true);
    DeleteEvent(id)
      .then((res) => {
        toast.success("Event deleted successfully!");
        setTimeout(() => {
          navigate("/events");
        }, 1500);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="w-full h-full flex flex-grow flex-col justify-start items-start p-3">
      <h1 className="text-2xl font-body font-bold">Tickets</h1>
      <div className="w-full border-t border-slate-300 my-3" />
      {loading ? (
        <div className="flex flex-col justify-center items-center flex-grow overflow-y-auto gap-1 w-full">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="flex flex-col justify-start items-start flex-grow overflow-y-auto gap-1 w-full">
          <div className="w-full flex flex-row justify-between items-center px-6">
            <div className="flex flex-row justify-start items-center gap-2">
              <p className="text-sm font-body font-medium">
                Event Date:{" "}
                {dayjs(event.startDate).format("MMM D YYYY[, at] hh:mm A")}
              </p>
              <button
                className="text-xs font-body font-medium text-white bg-black px-3 py-1"
                onClick={() => setEditDate(true)}
              >
                EDIT
              </button>
            </div>
            <div className="flex flex-col justify-center items-end gap-1">
              <div className="flex flex-row justify-start items-center gap-2">
                <p className="text-sm font-body font-medium text-right">
                  Tickets Available: {event.ticketsAvailable}/
                  {event.ticketsAvailable + event.ticketsSold}
                </p>
                <button
                  className="text-xs font-body font-medium text-white bg-black px-3 py-1"
                  onClick={() => setEditSeats(true)}
                >
                  EDIT
                </button>
              </div>
              {event.ticketsSold === 0 ? (
                <button
                  className="text-xs font-body font-medium text-white bg-red-500 px-16 py-1"
                  onClick={() => submitEventDelete()}
                >
                  DELETE EVENT
                </button>
              ) : null}
            </div>
          </div>
          <div className="w-full border-t border-slate-300 my-3" />
          {tickets.map((ticket) => (
            <TicketElement
              ticket={ticket}
              eventId={id}
              validateTicket={validateTicket}
              key={ticket.purchaseId}
            />
          ))}
        </div>
      )}
      <Transition.Root show={deleteEvent} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setDeleteEvent}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-transparent"
                      onClick={() => setDeleteEvent(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Delete Event
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this event?
                          <br />
                          This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center bg-red-500 px-3 py-2 text-sm font-semibold text-white hover:bg-red-600 sm:ml-3 sm:w-auto"
                      onClick={() => setDeleteEvent(false)}
                    >
                      Delete Event
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setDeleteEvent(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={editDate} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setEditDate}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                      <PencilSquareIcon
                        className="h-6 w-6 text-gray-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Edit Event Date
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Please pick the new date you wish to designate for
                          this event
                        </p>
                      </div>
                      <div className="mt-2">
                        <div className="mx-auto flex flex-row justify-between items-center gap-4 w-full">
                          <p className="font-body text-sm uppercase font-medium tracking-wide">
                            Event Date
                          </p>
                          <DatePicker
                            onChange={datePick}
                            style={{ width: "70%" }}
                          />
                        </div>
                        <div className="mx-auto flex flex-row justify-between items-center gap-4 w-full mt-2">
                          <p className="font-body text-sm uppercase font-medium tracking-wide">
                            Event Time
                          </p>
                          <Select
                            placeholder="Select Time"
                            style={{ width: "70%" }}
                            onChange={timePick}
                            options={[
                              {
                                value: "10:00 AM",
                                label: "10:00 AM",
                              },
                              {
                                value: "10:30 AM",
                                label: "10:30 AM",
                              },
                              {
                                value: "11:00 AM",
                                label: "11:00 AM",
                              },
                              {
                                value: "11:30 AM",
                                label: "11:30 AM",
                              },
                              {
                                value: "12:00 PM",
                                label: "12:00 PM",
                              },
                              {
                                value: "12:30 PM",
                                label: "12:30 PM",
                              },
                              {
                                value: "01:00 PM",
                                label: "1:00 PM",
                              },
                              {
                                value: "01:30 PM",
                                label: "1:30 PM",
                              },
                              {
                                value: "02:00 PM",
                                label: "2:00 PM",
                              },
                              {
                                value: "02:30 PM",
                                label: "2:30 PM",
                              },
                              {
                                value: "03:00 PM",
                                label: "3:00 PM",
                              },
                              {
                                value: "03:30 PM",
                                label: "3:30 PM",
                              },
                              {
                                value: "04:00 PM",
                                label: "4:00 PM",
                              },
                              {
                                value: "04:30 PM",
                                label: "4:30 PM",
                              },
                              {
                                value: "05:00 PM",
                                label: "5:00 PM",
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-900 sm:col-start-2"
                      onClick={() => submitDateEdit()}
                    >
                      Update Date/Time
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => cancelDateEdit()}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={editSeats} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setEditSeats}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                      <PencilSquareIcon
                        className="h-6 w-6 text-gray-500"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Edit Ticket Quantity
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Please enter the new ticket quantity to designate for
                          this event
                        </p>
                      </div>
                      <div className="mt-5">
                        <div className="mx-auto flex flex-row justify-between items-center gap-4 w-full">
                          <p className="font-body text-sm uppercase font-medium tracking-wide">
                            Tickets Available
                          </p>
                          <InputNumber
                            defaultValue={event.ticketsAvailable}
                            onChange={(v) => setAvailableSeats(v)}
                            style={{ width: "50%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-900 sm:col-start-2"
                      onClick={() => submitSeatEdit()}
                    >
                      Update Number of Tickets
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => cancelSeatEdit()}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

const TicketElement = ({ ticket, eventId, validateTicket }) => {
  let quantity = 0;
  for (let i = 0; i < ticket.events.length; i++) {
    if (ticket.events[i].eventId === eventId) {
      quantity = ticket.events[i].quantity;
    }
  }

  const navigate = useNavigate();

  return (
    <div
      className="w-full my-2 border border-slate-600 flex flex-row justify-between items-center px-3 py-2"
      key={ticket.purchaseId}
    >
      <div className="flex flex-col justify-start items-start w-1/3">
        <p className="font-body text-sm">
          {ticket.firstName} {ticket.lastName}
        </p>
        <p className="font-body text-xs text-gray-500">
          {ticket.email} | ({ticket.phone.slice(0, 3)}){" "}
          {ticket.phone.slice(3, 6)}-{ticket.phone.slice(6, 10)}
        </p>
      </div>
      <div className="flex flex-col justify-center items-start">
        <p className="font-body text-sm">Tickets Purchased: {quantity}</p>
        <p className="font-body text-xs text-gray-500">
          Purchased On: {dayjs(ticket.purchaseDate).format("lll")}
        </p>
      </div>
      <div className="flex flex-col justify-center items-end w-1/3">
        <button
          className="bg-black text-white px-4 py-2 text-xs font-semibold uppercase"
          onClick={() => navigate(`/ticketDetails/${ticket.purchaseId}`)}
        >
          View Details
        </button>
      </div>
    </div>
  );
};

export default Tickets;

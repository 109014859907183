import { useState } from "react";
import { DatePicker, InputNumber, Select } from "antd";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { NewEvent } from "../actions/events";
import { useNavigate } from "react-router-dom";

const AddEvent = () => {
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [ticketsAvailable, setTicketsAvailable] = useState(40);

  const navigate = useNavigate();

  const datePick = (date, dateString) => {
    setEventDate(dayjs(date).format("YYYY-MM-DD"));
  };

  const timePick = (val) => {
    setEventTime(val);
  };

  const addEvent = () => {
    let error = false;
    if (eventDate === "") {
      toast.error("Please select a date for the event.");
      error = true;
    } else if (eventTime === "") {
      toast.error("Please select a time for the event.");
      error = true;
    } else if (ticketsAvailable === 0 || ticketsAvailable < 0) {
      toast.error(
        "Please enter a valid number of tickets available for the event."
      );
      error = true;
    }
    if (!error) {
      let date = eventDate + " " + eventTime;
      let formatted = dayjs(date, "YYYY-MM-DD hh:mm A");
      let toSend = {
        startDate: formatted.toJSON(),
        ticketsAvailable: ticketsAvailable,
      };
      NewEvent(toSend)
        .then((res) => {
          toast.success("Event added successfully.");
          setTimeout(() => {
            navigate("/events");
          }, 1500);
        })
        .catch((err) => {
          toast.error("Something went wrong. Please try again later.");
        });
    }
  };

  return (
    <div className="w-full flex flex-grow flex-col justify-start items-start p-6 gap-2 max-h-[calc(100vh-48px)]">
      <div className="w-full flex flex-row justify-between items-center">
        <h1 className="text-2xl font-body font-bold">Add Event</h1>
      </div>
      <div className="w-full border-t border-slate-300 my-3" />
      <div className="mx-auto flex flex-row justify-between items-center gap-4 w-1/3">
        <p className="font-body text-sm uppercase font-medium tracking-wide">
          Event Date
        </p>
        <DatePicker onChange={datePick} />
      </div>
      <div className="mx-auto flex flex-row justify-between items-center gap-4 w-1/3">
        <p className="font-body text-sm uppercase font-medium tracking-wide">
          Event Time
        </p>
        <Select
          placeholder="Select Time"
          style={{ width: 137 }}
          onChange={timePick}
          options={[
            {
              value: "10:00 AM",
              label: "10:00 AM",
            },
            {
              value: "10:30 AM",
              label: "10:30 AM",
            },
            {
              value: "11:00 AM",
              label: "11:00 AM",
            },
            {
              value: "11:30 AM",
              label: "11:30 AM",
            },
            {
              value: "12:00 PM",
              label: "12:00 PM",
            },
            {
              value: "12:30 PM",
              label: "12:30 PM",
            },
            {
              value: "01:00 PM",
              label: "1:00 PM",
            },
            {
              value: "01:30 PM",
              label: "1:30 PM",
            },
            {
              value: "02:00 PM",
              label: "2:00 PM",
            },
            {
              value: "02:30 PM",
              label: "2:30 PM",
            },
            {
              value: "03:00 PM",
              label: "3:00 PM",
            },
            {
              value: "03:30 PM",
              label: "3:30 PM",
            },
            {
              value: "04:00 PM",
              label: "4:00 PM",
            },
            {
              value: "04:30 PM",
              label: "4:30 PM",
            },
            {
              value: "05:00 PM",
              label: "5:00 PM",
            },
          ]}
        />
      </div>
      <div className="mx-auto flex flex-row justify-between items-center gap-4 w-1/3">
        <p className="font-body text-sm uppercase font-medium tracking-wide">
          Tickets Available
        </p>
        <InputNumber
          defaultValue={40}
          onChange={(v) => setTicketsAvailable(v)}
        />
      </div>
      <div className="mx-auto flex flex-row justify-between items-center gap-4 w-1/3 mt-2">
        <button
          className="w-full bg-black text-white py-2 text-sm font-semibold font-body tracking-wide"
          onClick={() => addEvent()}
        >
          ADD EVENT
        </button>
      </div>
    </div>
  );
};

export default AddEvent;

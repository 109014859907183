import React, { useReducer } from "react";

const AuthContext = React.createContext();

const initAuth = {
  isAuth: false,
  token: null,
  user: {},
  email: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "valid-login":
      localStorage.setItem("auth-token", action.payload.token);
      return {
        isAuth: true,
        token: action.payload.token,
        email: action.payload.email,
        user: action.payload.user,
      };
    case "logged-out":
      localStorage.removeItem("auth-token");
      return { isAuth: false };
    default:
      return initAuth;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initAuth);
  return (
    <AuthContext.Provider value={{ ...state, authDispatch: dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };

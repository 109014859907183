import { useState, useRef, useEffect } from "react";
import { InputNumber } from "antd";
import { toast } from "react-hot-toast";
import { CheckTicket, ClaimTicket } from "../actions/events";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const TicketDetails = () => {
  // eslint-disable-next-line
  const [scan, setScan] = useState("");
  const [ticket, setTicket] = useState(null);

  let { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (id) {
        CheckTicket({ ticketId: id })
          .then((res) => {
            setTicket(res.data);
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            setTimeout(() => {
              navigate("/events");
            }, 2000);
          });
      } else {
        toast.error("Invalid Ticket ID");
        setTimeout(() => {
          navigate("/events");
        }, 2000);
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const renderTicketStatus = (status) => {
    if (status === "valid") {
      return "Valid";
    } else if (status === "used") {
      return "Used";
    } else if (status === "invalid" || "pending" || "") {
      return "Invalid";
    }
  };

  const claimTicket = (eventId, quantity) => {
    ClaimTicket({
      eventId: eventId,
      purchaseId: ticket.purchaseId,
      quantity: quantity,
    })
      .then((res) => {
        CheckTicket({ ticketId: ticket.purchaseId })
          .then((res) => {
            setTicket(res.data);
            toast.success("Ticket Claimed");
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const clearData = () => {
    setTicket(null);
  };

  const EventBlock = ({ event, eventId }) => {
    const [claimQuantity, setClaimQuantity] = useState(1);

    const addOne = () => {
      if (claimQuantity < event.quantity - event.used) {
        setClaimQuantity(claimQuantity + 1);
      } else {
        toast.error("Cannot claim more than purchased quantity");
      }
    };

    const removeOne = () => {
      if (claimQuantity > 1) {
        setClaimQuantity(claimQuantity - 1);
      } else {
        toast.error("At least one ticket must be claimed");
      }
    };

    const submitClaim = () => {
      claimTicket(eventId, claimQuantity);
    };

    return (
      <div className="w-2/3 flex flex-row justify-between items-center border border-gray-200 p-3">
        <div className="flex flex-col justify-center items-start gap-1">
          <p className="font-body uppercase text-sm font-semibold">
            {dayjs(event.startDate).format("MMM D YYYY[, at] h A")}
          </p>
          <p className="font-body uppercase text-xs font-medium">
            Quantity Purchased: {event.quantity}
          </p>
          <p className="font-body uppercase text-xs font-medium">
            Status: {event.used}/{event.quantity} Tickets Used
          </p>
        </div>
        <div className="h-full flex flex-col justify-center items-center gap-1">
          <div className="flex flex-row justify-center items-center w-[150px]">
            <button
              className="bg-black text-white w-1/4 h-full border-4 border-black"
              onClick={() => removeOne()}
            >
              -
            </button>
            <InputNumber
              min={1}
              max={event.quantity - event.used}
              value={claimQuantity}
              style={{
                borderRadius: 0,
                width: "50%",
                borderColor: "black",
                textAlign: "center",
              }}
              className="numberCenter"
              controls={false}
            />
            <button
              className="bg-black text-white w-1/4 h-full border-4 border-black"
              onClick={() => addOne()}
            >
              +
            </button>
          </div>
          <button
            className="w-[150px] bg-black text-white px-4 py-2 text-xs font-semibold disabled:border disabled:border-gray-600 disabled:bg-white disabled:text-gray-600 disabled:cursor-not-allowed"
            onClick={() => submitClaim()}
            disabled={event.quantity === event.used ? true : false}
          >
            {event.quantity !== event.used
              ? "CLAIM TICKETS"
              : "ALREADY CLAIMED"}
          </button>
        </div>
      </div>
    );
  };

  const amountPaid = () => {
    let totalQuantity = 0;
    for (let i = 0; i < ticket.events.length; i++) {
      totalQuantity += ticket.events[i].quantity;
    }
    let paid = totalQuantity * 25;
    let received = ticket.paymentData.amount_received / 100;
    if (received === paid) {
      return "Paid in Full";
    } else if (received === totalQuantity * 20) {
      return "Paid with Member Discount";
    } else if (received === 0) {
      return "Not Paid";
    } else {
      return "Unknown";
    }
  };

  return (
    <div className="w-full h-full flex flex-grow flex-col justify-start items-start p-3">
      <h1 className="text-xl font-body font-bold">Ticket Details</h1>
      <div className="w-full border-t border-slate-300 my-3" />
      <div className="flex flex-col justify-center items-center flex-grow overflow-y-auto gap-1 w-full">
        {!ticket ? (
          <h1 className="font-body uppercase text-lg font-semibold">
            Finding the ticket...
          </h1>
        ) : (
          <>
            <h4 className="font-body uppercase font-medium text-xs">
              Amount Paid: {amountPaid()}
            </h4>
            <h1 className="font-body uppercase text-2xl font-semibold">
              Ticket Found | {renderTicketStatus(ticket.status)}
            </h1>
            <div className="w-2/3 flex flex-row justify-between items-center">
              <p className="font-body uppercase text-sm font-semibold w-1/3">
                {ticket.firstName} {ticket.lastName}
              </p>
              <p className="font-body text-sm font-semibold text-slate-500 w-1/3 text-center">
                <span className="text-black">EMAIL: </span>
                {ticket.email}
              </p>
              <p className="font-body text-sm font-semibold text-slate-500 w-1/3 text-end">
                <span className="text-black">PHONE: </span>(
                {ticket.phone.slice(0, 3)}) {ticket.phone.slice(3, 6)}-
                {ticket.phone.slice(6, 10)}
              </p>
            </div>
            <div className="w-2/3 my-2 border-t border-gray-200" />
            <p className="font-body font-semibold text-black uppercase w-2/3 mb-3">
              Event Tickets Purchased:
            </p>
            <div className="w-full flex flex-col justify-start items-center gap-1 max-h-[40vh] overflow-y-auto">
              {ticket.events.map((event) => (
                <EventBlock
                  event={event}
                  eventId={event.eventId}
                  className="w-1/3"
                />
              ))}
            </div>
            <button
              className="px-4 py-2 text-sm bg-black text-white mt-5"
              onClick={() => navigate(-1)}
            >
              GO BACK
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default TicketDetails;

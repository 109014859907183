import { useState, useRef } from "react";
import { SignIn } from "../actions/auth";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Login = ({ authState, authDispatch }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const passwordField = useRef();
  const submitLogin = () => {
    setLoading(true);
    SignIn({ email, password })
      .then((res) => {
        let payload = {
          token: res.data.token,
          email: email,
          user: res.data.user,
        };
        localStorage.setItem("auth-token", res.data.token);
        authDispatch({
          type: "valid-login",
          payload: payload,
        });
        toast.success("Logged in successfully!", { id: "loginMsg" });
        setTimeout(() => {
          navigate("/");
        }, 700);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message, {
          position: "top-right",
        });
      });
  };

  const handleEmailEnter = (event) => {
    if (event.key === "Enter") {
      passwordField.current.focus();
    }
  };

  const handlePasswordEnter = (event) => {
    if (event.key === "Enter") {
      submitLogin();
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col justify-center h-full items-center py-16 w-full font-body">
      <main className="w-full max-w-md mx-auto p-6">
        <div className="bg-white border border-gray-200 rounded-xl shadow-sm">
          <div className="p-4 sm:p-7">
            <div className="text-center">
              <h1 className="block text-2xl font-bold text-gray-800">
                Sign in
              </h1>
            </div>

            <div className="mt-5">
              <div className="grid gap-y-4">
                <div>
                  <label htmlFor="email" className="block text-sm mb-2">
                    Email address
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="py-3 px-4 block w-full border border-gray-200 text-sm focus:border-gray-500 focus:ring-transparent focus:outline-none disabled:bg-slate-700 disabled:hover:bg-slate-700 disabled:text-slate-400 disabled:hover:text-slate-400"
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={handleEmailEnter}
                      disabled={loading}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between items-center">
                    <label htmlFor="password" className="block text-sm mb-2">
                      Password
                    </label>
                    <a
                      className="text-sm text-blue-600 decoration-2 hover:underline font-medium hidden"
                      href="/reset-password"
                    >
                      Forgot password?
                    </a>
                  </div>
                  <div className="relative">
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="py-3 px-4 block w-full border border-gray-200 text-sm focus:border-gray-500 focus:ring-transparent focus:outline-none disabled:bg-slate-700 disabled:hover:bg-slate-700 disabled:text-slate-400 disabled:hover:text-slate-400"
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={handlePasswordEnter}
                      ref={passwordField}
                      disabled={loading}
                    />
                  </div>
                </div>
                <button
                  className="mt-3 py-3 px-4 inline-flex justify-center items-center gap-2 uppercase font-body text-sm font-semibold bg-black text-white transition-all disabled:bg-slate-700 disabled:hover:bg-slate-700 disabled:text-slate-400 disabled:hover:text-slate-400"
                  disabled={loading}
                  onClick={() => submitLogin()}
                >
                  {loading ? "Processing" : "Sign in"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;

import axiosInstance from "./axios";

export const GetEvents = () => {
  return axiosInstance.get("/events");
};

export const NewEvent = (data) => {
  return axiosInstance.post("/events", data);
};

export const GetEvent = (id) => {
  return axiosInstance.get(`/events/${id}`);
};

export const CheckTicket = (data) => {
  return axiosInstance.post("/events/checkTicket", data);
};

export const ClaimTicket = (data) => {
  return axiosInstance.post("/events/claimTicket", data);
};

export const UpdateEvent = (id, data) => {
  return axiosInstance.put(`/events/${id}`, data);
};

export const DeleteEvent = (id) => {
  return axiosInstance.delete(`/events/${id}`);
};

const { useNavigate } = require("react-router-dom");

const Home = () => {
  const navigate = useNavigate();

  const Block = ({ title, description, link }) => {
    return (
      <div
        className="w-1/4 h-28 bg-black flex flex-col justify-center items-center cursor-pointer"
        onClick={() => navigate(link)}
      >
        <p
          className="font-semibold uppercase cursor-pointer text-white"
          onClick={() => navigate(link)}
        >
          {title}
        </p>
        <p
          className="font-semibold uppercase text-white text-xs"
          onClick={() => navigate(link)}
        >
          {description}
        </p>
      </div>
    );
  };

  return (
    <div className="w-full h-full flex flex-grow flex-col justify-start items-start p-3">
      <div className="flex flex-col justify-start items-center w-full flex-grow p-10">
        <h2 className="font-body text-center font-semibold text-2xl">
          Welcome Back!
        </h2>
        <h3 className="font-body text-center uppercase font-semibold text-sm">
          What would you like to do?
        </h3>
        <div className="flex flex-grow flex-row justify-center items-center gap-5 w-full">
          <Block
            title="Manage Events"
            description="View, Add, Modify & Remove Events"
            onClick={() => navigate("/events")}
            link="/events"
          />
          <Block
            title="Scan Tickets"
            description="Validate and claim member tickets"
            onClick={() => navigate("/scanTickets")}
            link="/scanTickets"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { GetEvents } from "../actions/events";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetEvents()
        .then((res) => {
          setEvents(res.data);
          setTimeout(() => {
            setLoading(false);
          }, 700);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  return (
    <div className="w-full flex flex-grow flex-col justify-start items-start p-6 max-h-[calc(100vh-48px)]">
      <div className="w-full flex flex-row justify-between items-center">
        <h1 className="text-2xl font-body font-bold">Events</h1>
        <button
          className="bg-black text-white font-body font-semibold text-xs uppercase px-4 py-2"
          onClick={() => navigate("/addEvent")}
        >
          Add Event
        </button>
      </div>
      <div className="w-full border-t border-slate-300 my-3" />
      <div className="flex flex-col justify-start items-center flex-grow overflow-y-auto gap-1 w-full">
        {events.map((event) => (
          <EventElement
            startDate={event.startDate}
            key={event.eventId}
            eventId={event.eventId}
            available={event.available}
          />
        ))}
      </div>
    </div>
  );
};

const EventElement = (props) => {
  const navigate = useNavigate();
  return (
    <div className="w-full my-2 border border-slate-600 flex flex-row justify-between items-center px-3 py-2">
      <div className="flex flex-col justify-start items-start">
        <h1 className="font-body font-medium">Time Detectives</h1>
        <p className="font-body text-sm">
          {dayjs(props.startDate).format("MMM D YYYY[, at] h A")}
        </p>
      </div>
      <p className="font-body text-sm">
        Tickets Available:{" "}
        <span className="font-semibold">{props.available}</span>
      </p>
      <button
        className="px-4 py-2 bg-black text-white uppercase text-xs font-body font-bold"
        onClick={() => navigate(`/tickets/${props.eventId}`)}
      >
        MANAGE EVENT
      </button>
    </div>
  );
};

export default Events;

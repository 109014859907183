import { useState, useEffect } from "react";
import Nav from "./components/nav";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Events from "./pages/events";
import Tickets from "./pages/tickets";
import Home from "./pages/home";
import Login from "./pages/login";
import { Toaster } from "react-hot-toast";
import { GetUser } from "./actions/auth";
import AddEvent from "./pages/addEvent";
import ScanTickets from "./pages/scanTickets";
import TicketDetails from "./pages/ticketDetails";

function App({ authState, authDispatch }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (token) {
      GetUser()
        .then((response) => {
          if (response.data.message === "Unauthorized User | LOGOUT") {
            authDispatch({ type: "logged-out" });
            localStorage.removeItem("auth-token");
            setLoading(false);
            history.push("/login");
          } else {
            authDispatch({
              type: "valid-login",
              payload: {
                token: localStorage.getItem("auth-token"),
                user: response.data,
              },
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          authDispatch({ type: "logged-out" });
          localStorage.removeItem("auth-token");
          setLoading(false);
          history.push("/login");
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [authDispatch]);

  let history = useNavigate();

  return !loading ? (
    <div className="w-full h-screen max-h-[100vh] overflow-x-hidden flex flex-col justify-start items-center">
      <Nav authState={authState} authDispatch={authDispatch} />
      <Toaster
        position="top-right"
        toastOptions={{ style: { borderRadius: 0 } }}
      />
      <Routes>
        <Route
          path="/login"
          element={
            authState.isAuth ? (
              <Home />
            ) : (
              <Login authState={authState} authDispatch={authDispatch} />
            )
          }
        />
        <Route
          path="/events"
          element={authState.isAuth ? <Events /> : <Navigate to="/login" />}
        />
        <Route
          path="/addEvent"
          element={authState.isAuth ? <AddEvent /> : <Navigate to="/login" />}
        />
        <Route
          path="/tickets/:id"
          element={authState.isAuth ? <Tickets /> : <Navigate to="/login" />}
        />
        <Route
          path="/scanTickets"
          element={
            authState.isAuth ? <ScanTickets /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/ticketDetails/:id"
          element={
            authState.isAuth ? <TicketDetails /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/"
          element={authState.isAuth ? <Home /> : <Navigate to="/login" />}
        />
      </Routes>
    </div>
  ) : (
    <div className="w-full h-screen max-h-[100vh] overflow-x-hidden flex flex-col justify-center items-center">
      <p>Loading</p>
    </div>
  );
}

export default App;
